
import gsap from "gsap";

const globalNav = () => {
    const nav = document.getElementById("js-globalnav");
    const btn = document.getElementById("js-gn-menu");
    // const menu = nav.getElementsByClassName("gnav-item");

    console.log(nav)

    const open = () => {
        document.querySelector('body').style.overflow = "hidden";
        nav.classList.add("is-open");
    };

    const close = () => {
        document.querySelector('body').style.overflow = "auto";
        nav.classList.remove("is-open");
    };

    btn.addEventListener("click", () => {
        btn.querySelector('.hamburger').classList.toggle("is-active");
        btn.querySelector('.hamburger').classList.contains("is-active") ? open() : close();
    });

    nav.classList.remove('preload');

};

export default globalNav;